@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-Thin.woff2') format('woff2'),
    url('./static/fonts/Gilroy-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-ExtraBold.woff2') format('woff2'),
    url('./static/fonts/Gilroy-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-BlackItalic.woff2') format('woff2'),
    url('./static/fonts/Gilroy-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-SemiBold.woff2') format('woff2'),
    url('./static/fonts/Gilroy-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-Bold.woff2') format('woff2'),
    url('./static/fonts/Gilroy-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-Regular.woff2') format('woff2'),
    url('./static/fonts/Gilroy-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-ThinItalic.woff2') format('woff2'),
    url('./static/fonts/Gilroy-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-HeavyItalic.woff2') format('woff2'),
    url('./static/fonts/Gilroy-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-ExtraBold.woff2') format('woff2'),
    url('./static/fonts/Gilroy-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-Heavy.woff2') format('woff2'),
    url('./static/fonts/Gilroy-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-Light.woff2') format('woff2'),
    url('./static/fonts/Gilroy-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-LightItalic.woff2') format('woff2'),
    url('./static/fonts/Gilroy-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-UltraLightItalic.woff2') format('woff2'),
    url('./static/fonts/Gilroy-UltraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-SemiBoldItalic.woff2') format('woff2'),
    url('./static/fonts/Gilroy-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-Black.woff2') format('woff2'),
    url('./static/fonts/Gilroy-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-Light.woff2') format('woff2'),
    url('./static/fonts/Gilroy-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-UltraLight.woff2') format('woff2'),
    url('./static/fonts/Gilroy-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-Heavy.woff2') format('woff2'),
    url('./static/fonts/Gilroy-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-Medium.woff2') format('woff2'),
    url('./static/fonts/Gilroy-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-RegularItalic.woff2') format('woff2'),
    url('./static/fonts/Gilroy-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-MediumItalic.woff2') format('woff2'),
    url('./static/fonts/Gilroy-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-ExtraBoldItalic.woff2') format('woff2'),
    url('./static/fonts/Gilroy-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Gilroy';
  src: url('./static/fonts/Gilroy-BoldItalic.woff2') format('woff2'),
    url('./static/fonts/Gilroy-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

html {
  background: #fff;
}

body {
  margin: 0;
  font-family: 'Gilroy', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

a {
  color: #363537;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

hr {
  border: 0;
  border-top: 1px solid #363537;
  opacity: 0.1;
  margin: 20px 0;
}

ul,
ol,
figure {
  padding: 0;
  margin: 0;
}

.sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.Toastify__toast-container {
  max-width: 383px;
  width: 100% !important;
}

.Toastify__toast.success-bar {
  padding: 10px 15px 10px 10px;
  box-shadow: 0px 0px 20px #00000029;
  border-radius: 5px;
}

.Toastify__toast.success-bar .Toastify__toast-body {
  border-left: 3px solid #60D394;
  margin-right: 15px;
}

.Toastify__close-button {
  align-items: center;
  align-self: center;
}

@media only screen and (max-width: 767px) {
  .hidden-mobile {
    display: none !important;
  }
}

.hidden-desktop {
  display: none !important;
}
@media only screen and (max-width: 767px) {
  .hidden-desktop {
    display: block !important;
  }
}
